import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gamesActions, reportActions } from '../../_actions';
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';


export default function Tnxreport() {
  let selector = useSelector(state => state)
  const dispatch = useDispatch();
  let { games } = selector ? selector : {};
  let { allMarket, allUserList } = games ? games : {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const today = new Date().toISOString().split('T')[0];


  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyWord, setkeyWord] = useState('');
  const [Options, setOptions] = useState([])
  const [toDate, settoDate] = useState('');
  const [selecteddata, setselecteddata] = useState([])
  let date = new Date();
  let formattedDate = moment(date).format('DD-MM-YYYY');
  const [fromDate, setfromDate] = useState(formattedDate);

  const [filterDateFromToday, setFilterDateFromToday] = useState(today)
  const [filterDateToToday, setFilterDateToToday] = useState(today)




  useEffect(() => {
    dispatch(reportActions.getAllTnxListForUsers());
    dispatch(gamesActions.getAllUser())
  }, []);



  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id
      }));
      // console.log(options);
      setOptions(options);
    }
  }, [allUserList])

  // console.log(Options)
  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));

  };

  const handleFromDate = (e) => {
    setFilterDateFromToday(e.target.value)
  }
  const handleToDate = (e) => {
    setFilterDateToToday(e.target.value)
  }

  const createGameSubmit = (e) => {
    e.preventDefault();
    let gameReq2 = {
      "createdAtStart": filterDateFromToday || "",
      "createdAtEnd": filterDateToToday || "",
      "userId": fieldsGame?.userId || "",
      "page": pageNo,
      "limit": size,
      "type": fieldsGame?.type || "",
    }
    dispatch(reportActions.getAllTnxListForUsers(gameReq2));
    // }
  };
  const handlePageClick = (data) => {
    // console.log("data_page__page__page__page__page_???", data);
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setCurrentPage(data.selected)
    setPageNo(data.selected + 1);
    let pageReq = {

      "createdAtStart": filterDateFromToday || "",
      "createdAtEnd": filterDateToToday || "",
      "userId": fieldsGame?.userId || "",
      "page": data.selected + 1,
      "limit": size,
      "type": fieldsGame?.type || "",
    }

    dispatch(reportActions.getAllTnxListForUsers(pageReq));
  }

  const handleInputSize = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    setSize(e.target.value)
    let pageReq = {
      // "type": "WITHDRAW",
      "keyWord": keyWord,
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": fromDate,
      "toDate": toDate,
      "size": value,
      "userId": selecteddata,
    }
    dispatch(reportActions.getBetsListForAdmin(pageReq));
  };

  let { TnxReport } = useSelector(state => state?.reports);
  let { transactions, loading, totalPages, totalRecords } = TnxReport ? TnxReport : {}

  return (
    <>
      <Loader loading={selector?.reports?.loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-10">
                      <sectiion className="flex justify-between ">
                        <div className='text-xl font-semibold'><span className='text-[#7D1970]'>User Transaction Report</span></div>
                      </sectiion>
                      <div className="px-6 text-left   rounded-lg">

                        <div className='flex justify-between md:flex-nowrap  gap-3 flex-wrap items-end  w-full '>

                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="gamename">From Date :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none uppercase"
                              id="date" name="date" placeholder="Enter date"
                              // value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""}
                              value={filterDateFromToday}
                              type="date" max={today}
                              onChange={handleFromDate}
                            />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>

                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="gamename">To Date :</label>
                            <input className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none uppercase"
                              id="date" name="todate" placeholder="Enter date"
                              //  value={fieldsGame && fieldsGame["todate"] ? fieldsGame["todate"] : ""} 
                              value={filterDateToToday}
                              type="date" max={today} onChange={handleToDate} />
                            {errorsGame && errorsGame["todate"] ?
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["todate"]}
                              </div>
                              : null}
                          </div>


                          <div className="mt-1 shadow-sm w-full">
                            <label className="block text-gray-700 text-base mb-2" htmlFor="status">Status</label>
                            <select
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none capitalize"
                              id="status"
                              name="status"
                              value={fieldsGame && fieldsGame["status"] ? fieldsGame["status"] : ""}
                              onChange={inputChangeCreate}
                            >
                              <option value="">Select Status</option>
                              <option value="1">Success</option>
                              <option value="2">Rejected</option>
                            </select>
                            {errorsGame && errorsGame["status"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["status"]}
                              </div>
                            ) : null}
                          </div>








                          <div className="mt-1 shadow-sm w-full">
                            <label className="block text-gray-700 text-base mb-2" htmlFor="status">Type :</label>
                            <select
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-lg focus:outline-none capitalize"
                              id="type"
                              name="type"
                              value={fieldsGame && fieldsGame["type"] ? fieldsGame["type"] : ""}
                              onChange={inputChangeCreate}
                            >
                              <option value="">Select type</option>

                              <option value="DEPOSIT_ADMIN">DEPOSIT ADMIN</option>

                              <option value="WITHDRAW_ADMIN">WITHDRAW ADMIN</option>

                            </select>
                            {errorsGame && errorsGame["type"] ? (
                              <div className="text-red-600 invalid-feedback">
                                {errorsGame["type"]}
                              </div>
                            ) : null}
                          </div>




                          <div className="w-full flex mt-2 md:mt-0 justify-start items-end">
                            <button className="flex w-full justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[#7D1970] border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                          </div>


                        </div>
                      </div>

                      <div className="px-6  text-left bg-white  rounded-lg border shadow">

                        <div className="relative overflow-x-auto p-5">


                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th className="px-6 py-3">#</th>
                                <th className="px-6 py-3">user name</th>
                                <th className="px-6 py-3">amount</th>
                                <th className="px-6 py-3">Status</th>
                                <th className="px-6 py-3">Type</th>
                                <th className="px-6 py-3">Date</th>

                              </tr>
                            </thead>
                            <tbody>
                              {transactions && transactions.length > 0 ? transactions.map((element, index) => {
                                console.log("element", element)
                                return (
                                  <React.Fragment key={index}>
                                    <tr key={index} className="bg-white border-b border-gray-400 capitalize">
                                      <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                        {offset + index + 1}
                                      </th>
                                      <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap ">
                                        <Link to={`/app/user/${element.userId?._id}`}>{element && element.userId && element.userId.userName ? element.userId.userName : "-"}</Link>

                                      </th>
                                      <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                        {element && element.amount ? element.amount : "-"}
                                      </th>

                                      <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                        {element && element.status && element.status || "-"}
                                      </th>
                                      <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                        {element && element.type ? element.type : "-"}
                                      </th>

                                      <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                        {element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MMM-YYYY hh:mm A") : "-"}
                                      </th>


                                    </tr>
                                  </React.Fragment>
                                )

                              }) : <td>No Data Found</td>
                              }
                            </tbody>
                          </table>
                        </div>
                        {



                          totalRecords && totalRecords > size ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={totalRecords / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            /> : null

                        }

                      </div>

                    </div>

                  </div>

                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

