import { reportConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
export const reportActions = {
    getBetsListForAdmin,
    getWinningList,
    getNumberWiseMarketSum,
    getAllTnxListForUsers,
};


function getAllTnxListForUsers(data) {
    const queryString = new URLSearchParams(data).toString();
    console.log("queryString", queryString)
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: {},
        endPoint: `/getAllTnxListForUsers?${queryString}`
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.getAllTnxListForUsers_REQUEST, report } }
    function success(report) { return { type: reportConstants.getAllTnxListForUsers_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.getAllTnxListForUsers_FAILURE, error } }
}




function getNumberWiseMarketSum(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberWiseMarketSum'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_FAILURE, error } }
}
function getWinningList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getWinningList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_WIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_WIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_WIN_FAILURE, error } }
}
function getBetsListForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getBetsListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_FAILURE, error } }
}