import {
  reportConstants
} from '../_constants';

export default function reports(state = {}, action) {
  switch (action.type) {
    
    
case reportConstants.getAllTnxListForUsers_REQUEST:
  return {
    ...state,
    loading: true
  };
case reportConstants.getAllTnxListForUsers_SUCCESS:
  return {
    ...state,
    loading: false,

    TnxReport:action?.report?.data
  };
case reportConstants.getAllTnxListForUsers_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };




    
    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_SUCCESS:
      return {
        ...state,
        loading: false,
        numberListData:action?.report?.data
      };
    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    
    

    case reportConstants.GET_WIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case reportConstants.GET_WIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        winList: action?.report?.data?.list,
        winTotal: action?.report?.data?.total,
        loading: false,
      };
    case reportConstants.GET_WIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        reportsList: action?.report?.data?.list,
        reportsTotal: action?.report?.data?.total,
        loading: false,
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state
  }
}