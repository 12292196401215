export const reportConstants = {

    GET_BETLIST_FOR_ADMIN_REQUEST: 'GET_BETLIST_FOR_ADMIN_REQUEST',
    GET_BETLIST_FOR_ADMIN_SUCCESS: 'GET_BETLIST_FOR_ADMIN_SUCCESS',
    GET_BETLIST_FOR_ADMIN_FAILURE: 'GET_BETLIST_FOR_ADMIN_FAILURE',

    GET_WIN_REQUEST: "GET_WIN_REQUEST",
    GET_WIN_SUCCESS: "GET_WIN_SUCCESS",
    GET_WIN_FAILURE: "GET_WIN_FAILURE",

    GET_NUMBER_WISE_MARKET_SUM_REQUEST: "GET_NUMBER_WISE_MARKET_SUM_REQUEST",
    GET_NUMBER_WISE_MARKET_SUM_SUCCESS: "GET_NUMBER_WISE_MARKET_SUM_SUCCESS",
    GET_NUMBER_WISE_MARKET_SUM_FAILURE: "GET_NUMBER_WISE_MARKET_SUM_FAILURE",


    getAllTnxListForUsers_REQUEST: "getAllTnxListForUsers_REQUEST",
    getAllTnxListForUsers_SUCCESS: "getAllTnxListForUsers_SUCCESS",
    getAllTnxListForUsers_FAILURE: "getAllTnxListForUsers_FAILURE",

};
