import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { IoChevronBackCircle } from "react-icons/io5";
import { bankActions, gamesActions, userActions, walletActions } from '../../_actions';
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from '../../components/Loader/Loader';
import ViewUserModal from './components/ViewUserModal/ViewUserModal';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { IoMdArrowRoundBack } from 'react-icons/io';
import ClientPaymentList from './components/ClientPaymentList';
import Multiselect from 'multiselect-react-dropdown';


const Dashboard = () => {
  const maxDate = new Date().toISOString().split('T')[0];
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { userId } = useParams();

  let selector = useSelector(state => state)
  const { paymentList, totalPayment } = useSelector(state => state.wallet);
  const { userDetailsById } = useSelector(state => state.users);
  const [rowData, setrowData] = useState({});
  const [sortBy, setsortBy] = useState("asc");
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [viewModal, setviewModal] = useState(false);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [fieldsBank, setFieldsBank] = useState({});
  const [errorsBank, setErrorsBank] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [toDate, settoDate] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [fieldsUser, setFieldsUser] = useState({});
  const [errorsUser, setErrorsUser] = useState({});
  const [fromDate, setfromDate] = useState('');

  const [bankDetailsOfUser, setbankDetailsOfUser] = useState({});
  const [fromDate2, setfromDate2] = useState('');
  const [keyWord2, setkeyWord2] = useState('');
  const [keyWord, setkeyWord] = useState('');

  const [sortBy2, setsortBy2] = useState("asc");
  const [size, setSize] = useState(10);
  const [size2, setSize2] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [toDate2, settoDate2] = useState('');
  const [offset2, setOffset2] = useState(0);
  const [pageNo2, setPageNo2] = useState(1);
  const [eye1, seteye1] = useState(false);
  const [eye2, seteye2] = useState(false);
  let { bank } = selector ? selector : {};
  let { bankDetailsByAdmin, loading } = bank ? bank : {};
  // console.log(userDetailsById);

  useEffect(() => {

    let gameReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(gamesActions.getAllGames(gameReq));

    dispatch(userActions.getProfileforAdmin({ "userId": userId }));

    let paymentListReq = {
      "type": "DEPOSIT",
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size
    }


    let myBetReq2 = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy2,
      "pageNo": pageNo2,
      "size": size2,
      userId: userId
    }

    let myBetReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": pageNo,
      "size": size,
      userId: userId
    }

    dispatch(walletActions.getPaymentListForAdmin(paymentListReq));

    dispatch(bankActions.getClientBankDetail({ "userId": userId }))

    dispatch(userActions.transactionHistory(myBetReq2));

    dispatch(userActions.getBetsListByUserIdForAdmin(myBetReq))


  }, [userId, size2, size]);




  useEffect(() => {
    if (userDetailsById) {
      setFieldsUser(userDetailsById);
      let arraydata = userDetailsById && userDetailsById?.access && userDetailsById?.access.length > 0 && userDetailsById?.access.map(e => ({ name: e, id: e }))
      setSelectedOptions(arraydata)
    }
    if (bankDetailsByAdmin) {
      setbankDetailsOfUser(bankDetailsByAdmin)
    }


  }, [userDetailsById, bankDetailsByAdmin])




  const inputChangeUser = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUser(prevState => ({ ...prevState, [name]: value }));
    setErrorsUser(prevState => ({ ...prevState, [name]: "" }));
  };


  const updateUserSubmit = (e) => {
    if (handleValidationUserUpdate()) {
      const { userName, mobNo, _id } = fieldsUser;
      dispatch(userActions.updateUser({
        userName: userName && userName.trim(), mobNo: mobNo && mobNo.trim(),
        // email: email && email.trim(),
        userId: _id
      }));
    }
  };

  const handlePermission = (e) => {
    e.preventDefault();
    let access = selectedOptions && selectedOptions.length > 0 && selectedOptions.map(e => e.id) || [];
    dispatch(userActions.allowAccess({ "userId": userId, access: access }))

    console.log(selectedOptions)
  }


  const handleValidationUserUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
      formIsValid = false;
      errors.userName = "Enter userName";
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
      formIsValid = false;
      errors.mobNo = "Enter mobile Number";
    }

    // if (!fieldsUser.email || fieldsUser.email.trim() === "") {
    //   formIsValid = false;
    //   errors.email = "Enter email";
    // }

    setErrorsUser(errors);
    console.log('errorsBank!!!!!!!!!!!!!', errorsBank);
    return formIsValid;
  };


  const inputChangeBank = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setbankDetailsOfUser(prevState => ({ ...prevState, [name]: value }));
    setErrorsBank(prevState => ({ ...prevState, [name]: "" }));
  };


  const updateBankDetailsSubmit = (e) => {
    if (handleValidationBankUpdate()) {
      const { accountNo, name, ifsc, bankName, upiId, googlePay, phonePay } = bankDetailsOfUser;


      dispatch(walletActions.updateUserBankDetailByAdmin({
        userId: userId,
        accountNo: accountNo && accountNo,
        name: name && name.trim(),
        ifsc: ifsc && ifsc.trim(),
        bankName: bankName && bankName.trim(),
        upiId: upiId && upiId.trim(),
        googlePay: googlePay,
        phonePay: phonePay && phonePay,
      }));

    }
  };

  const handleValidationBankUpdate = () => {
    let formIsValid = true;
    let errors = {};



    if (!bankDetailsOfUser.accountNo || bankDetailsOfUser.accountNo === "") {
      formIsValid = false;
      errors.accountNo = "Enter accountNo";
    }

    if (!bankDetailsOfUser.name || bankDetailsOfUser.name.trim() === "") {
      formIsValid = false;
      errors.name = "Enter name";
    }

    if (!bankDetailsOfUser.ifsc || bankDetailsOfUser.ifsc.trim() === "") {
      formIsValid = false;
      errors.ifsc = "Enter ifsc";
    }

    if (!bankDetailsOfUser.bankName || bankDetailsOfUser.bankName.trim() === "") {
      formIsValid = false;
      errors.bankName = "Enter bankName";
    }

    if (!bankDetailsOfUser.upiId || bankDetailsOfUser.upiId.trim() === "") {
      formIsValid = false;
      errors.upiId = "Enter upiId";
    }

    setErrorsBank(errors);
    console.log('errorsBank!!!!!!!!!!!!!', errorsBank);
    return formIsValid;
  };


  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit2 = (e, data) => {
    console.log(e)
    setrowData({ ...e, key: data })
    setviewModal(true);
  }
  const createGameSubmit3 = (e, data) => {
    console.log(e)
    setrowData({ ...e, key: data })
    setviewModal(true);
  }




  const handleChange = (e) => {

    const { name, value } = e.target;
    setrowData(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  }

  const handleFund = (e) => {
    e.preventDefault()

    if (handleValidationCreateGame()) {

      let obj = {
        userId: rowData._id,
        amount: rowData?.amount || 0,
        password: rowData?.passwords || ""
      }
      console.log(rowData?.key === "Withdraw Fund")
      if (rowData?.key === "Withdraw Fund") {
        dispatch(walletActions.withdrawBalanceByAdmin(obj, userId));
      } else {
        dispatch(walletActions.depositeBalanceByAdmin(obj, userId));
      }

      setrowData({})
      setviewModal(false);

    }
  }

  const handleViewHideModal = () => {
    setrowData({})
    setviewModal(false);
    setFieldsGame({});
    setErrorsGame({});
    setFieldsUser({});
    setErrorsUser({});
  }



  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rowData.amount || rowData.amount === "") {
      formIsValid = false;
      errors.amount = "Enter Amount";
    }
    if (!rowData.passwords || rowData.passwords === "") {
      formIsValid = false;
      errors.passwords = "Enter Tnx password";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const rejectRequest = (data) => {
    let reqData = {
      "paymentId": data._id,
      "status": 2
    }

    let gamePageRefresh = {
      "type": "DEPOSIT",
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({

      title: 'Confirm to Reject?',
      message: `Are you sure you want to Rejected Request ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(walletActions.approvePaymentByAdmin(reqData, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const handleSelect = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    setSelectedOptions(selectedList);
  };

  const handlePageClick2 = (data) => {

    let offset = Math.ceil(data.selected * size);
    setCurrentPage2(data.selected)
    setOffset2(offset);
    setPageNo2(data.selected + 1);

    let myBetReq = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": data.selected + 1,
      "size": size,
      userId: userId
    }
    dispatch(userActions.transactionHistory(myBetReq))

  }
  let handleInputSize22 = (e) => {
    setPageNo2(1);
    setkeyWord2(e.target.value);
  }
  useEffect(() => {
    let myBetReq2 = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy2,
      "pageNo": pageNo2,
      "size": size2,
      userId: userId
    }
    dispatch(userActions.transactionHistory(myBetReq2));
  }, [keyWord2, size2])

  const handleInputSize = (e) => {
    const newSize = e.target.value;
    setPageNo2(1)
    setOffset(0)
    setCurrentPage2(0)
    setSize2(newSize)

  };

  const handleInputSize2 = (e) => {
    const newSize = e.target.value;
    setPageNo(1)
    setOffset(0)
    setCurrentPage(0)
    setSize(newSize)
  };


  let handleFilter2 = () => {
    let myBetReq2 = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy2,
      "pageNo": pageNo2,
      "size": size2,
      userId: userId
    }

    dispatch(userActions.transactionHistory(myBetReq2));
  }

  let changePasswordAdmin = () => {
    if (fieldsUser['passwords']) {
      let Obj = {
        userId: userId,
        password: fieldsUser['passwords']
      }
      dispatch(userActions.changePasswordAdmin(Obj))
      setFieldsUser(prevState => ({ ...prevState, passwords: "" }));
    }



  }
  let changetnxAdmin = () => {
    let Obj = {
      userId: userId,
      transactionPassword: fieldsUser['transactionPasswords']
    }
    if (fieldsUser['transactionPasswords']) {
      console.log(Obj)
      dispatch(userActions.changeTnxPasswordAdmin(Obj))
      setFieldsUser(prevState => ({ ...prevState, transactionPasswords: "" }));

    }
  }

  const disableUser = (data, userDetailsById) => {

    let userIdReq = {
      "userId": userId,
    }
    confirmAlert({

      title: userDetailsById?.isDisable ? 'Confirm to Disable?' : 'Confirm to Enable',
      message: userDetailsById?.isDisable ? `Are you sure you want to Disable ${data}?` : `Are you sure you want to Enable ${data}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userActions.updateUserStatus(userIdReq, null, userId))

        },
        {
          label: 'No'
        }
      ]
    });
  }

  const handlePageClick1 = (data) => {

    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected)
    setOffset(offset);
    setPageNo(data.selected + 1);

    let myBetReq = {
      "fromDate": fromDate2,
      "toDate": toDate2,
      "keyWord": keyWord2,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": data.selected + 1,
      "size": size,
      userId: userId
    }
    dispatch(userActions.getBetsListByUserIdForAdmin(myBetReq))

  }



  let handleFilter1 = () => {
    let myBetReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": sortBy,
      "pageNo": pageNo,
      "size": size,
      userId: userId
    }
    dispatch(userActions.getBetsListByUserIdForAdmin(myBetReq))
  }


  const options = [
    { name: 'Declare Result', id: "Declare Result" },
    { name: 'Winner List', id: "Winner List" },
    { name: 'Setting Management', id: "Setting Management" },
    // { name: 'Game Management', id: "Game Management" }
  ];


  let { users } = selector ? selector : {};
  let { tnxList, tnxTotal, betsListdata, betsListtotal } = users ? users : {}




  return (
    <>
      <Loader loading={loading} />
      <Loader loading={selector?.users?.loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className='px-6 pt-3'>
                    <IoMdArrowRoundBack className='text-2xl cursor-pointer hover:scale-150 duration-300' onClick={() => navigate(-1)} />
                  </div>
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-3">
                      <div className='w-full grid  lg:grid-cols-3 grid-cols-1 gap-3'>
                        <div className='w-full col-span-1 bg-white rounded-xl'>
                          <div className='bg-blue-600/20 rounded-t-xl p-4 flex justify-between place-items-center'>

                            <span className='text-[#7D1970] font-semibold capitalize'>User Name : {userDetailsById?.userName || ""}</span>

                            <div className='my-3'>
                              {userDetailsById?.isDisable ?
                                <button onClick={() => disableUser(userDetailsById?.userName, userDetailsById)}
                                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg"
                                >
                                  Enable
                                </button>
                                :
                                <button onClick={() => disableUser(userDetailsById?.userName, userDetailsById)}
                                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-center  text-white bg-red-700 rounded-lg"
                                >
                                  Disable
                                </button>

                              }
                            </div>

                          </div>

                          <div className='w-full space-y-4 p-5 border-t'>
                            <div className='w-full flex flex-col justify-start'>
                              <span className='text-black'>Available Balance : {userDetailsById?.wallet || 0}</span>
                              {/* <span className='text-black/70 font-semibold'></span> */}
                            </div>

                            <div className='w-full flex justify-between items-centerer space-x-5'>
                              <button className="w-full flex justify-center px-4 py-2 text-sm font-semibold text-white capitalize  transition duration-150 ease-in-out bg-green-500 border border-green-500 rounded-full focus:outline-none" type="button" onClick={() => createGameSubmit2(userDetailsById, "Add Fund")}>Add Fund</button>
                              <button className="w-full flex justify-center px-4 py-2 text-sm font-semibold text-white capitalize  transition duration-150 ease-in-out bg-red-500 border border-red-500 rounded-full focus:outline-none" type="button" onClick={() => createGameSubmit3(userDetailsById, "Withdraw Fund")}>Withdraw Fund</button>
                            </div>

                            <div>


                              <div className="">
                                <div className=''>
                                  <label className="block w-44 text-sm text-gray-700   " for="name">Change Password:</label>
                                </div>
                                <div className='flex justify-between gap-2  '>
                                  <div className='relative  w-full'>
                                    <input value={fieldsUser && fieldsUser["passwords"] ? fieldsUser["passwords"] : ""} className="  w-full border px-3 py-2 text-sm bg-white focus:outline-none rounded-md  "
                                      id="passwords" name="passwords"
                                      type={eye1 ? "text" : "password"} onChange={inputChangeUser} />
                                    {eye1 ? <FaEye onClick={() => seteye1((prev) => !prev)} className='absolute top-2 right-2' /> : <FaEyeSlash onClick={() => seteye1((prev) => !prev)} className='absolute top-2 right-2' />}
                                  </div>
                                  {errorsUser && errorsUser["passwords"] ?
                                    <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                      {errorsUser["passwords"]}
                                    </div>
                                    : null}
                                  <div className='w-[10%] '>
                                    <button onClick={() => changePasswordAdmin()} className='bg-blue-700 border text-white rounded-md px-1 py-1'>Save</button>
                                  </div>
                                </div>

                              </div>


                              {userDetailsById && userDetailsById?.roleId != 100 &&
                                <div className=" gap-2 ">
                                  <div className=''>
                                    <label className=" text-gray-700 text-sm " for="name">Change Tnx Password:</label>
                                  </div>
                                  <div className='  flex justify-between place-items-center gap-2'>
                                    <div className='relative w-full'>
                                      <input value={fieldsUser && fieldsUser["transactionPasswords"] ? fieldsUser["transactionPasswords"] : ""} className="border w-full  px-3 py-2 text-sm bg-white focus:outline-none rounded-md  "
                                        id="transactionPasswords" name="transactionPasswords"
                                        type={eye2 ? "text" : "password"} onChange={inputChangeUser} />
                                      {eye2 ? <FaEye onClick={() => seteye2((prev) => !prev)} className=' absolute top-3 right-2' /> : <FaEyeSlash onClick={() => seteye2((prev) => !prev)} className=' absolute top-3 right-2' />}
                                    </div>
                                    {errorsUser && errorsUser["transactionPasswords"] ?
                                      <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                        {errorsUser["transactionPasswords"]}
                                      </div>
                                      : null}
                                    <div className='w-[10%]'>
                                      <button onClick={() => changetnxAdmin()} className='bg-blue-700  text-white rounded-md px-1 py-1'>Save</button>
                                    </div>
                                  </div>


                                </div>}


                            </div>

                          </div>
                        </div>

                        <div className='w-full col-span-1 p-5 space-y-2 bg-white rounded-xl lg:block hidden '>
                          <div className='flex justify-between items-center  border-gray-400 pb-3'>
                            <span className="text-lg text-left font-semibold text-gray-900">Personal Information</span>
                          </div>
                          <div className=' flex  flex-wrap gap-3'>
                            <div className=" items-centerer gap-2 w-full">
                              <label className="block px-2 text-gray-700 text-base whitespace-nowrap" for="name">Full Name:</label>
                              <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                                id="userName" name="userName"
                                value={fieldsUser && fieldsUser["userName"] ? fieldsUser["userName"] : ""} type="text" onChange={inputChangeUser} />
                              {errorsUser && errorsUser["userName"] ?
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["userName"]}
                                </div>
                                : null}
                            </div>



                            <div className=" items-center gap-2 w-full">
                              <label className="block px-2 text-gray-700 text-base" for="name">Mobile:</label>
                              <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                                id="mobNo" name="mobNo"
                                value={fieldsUser && fieldsUser["mobNo"] ? fieldsUser["mobNo"] : ""} type="text" onChange={inputChangeUser} />
                              {errorsUser && errorsUser["mobNo"] ?
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["mobNo"]}
                                </div>
                                : null}
                            </div>


                            <div className=" items-center gap-2 w-full">
                              <label className="block px-2 text-gray-700 text-base whitespace-nowrap" for="name">Creation Date:</label>
                              <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                                id="createdAt" name="createdAt"
                                value={fieldsUser && fieldsUser["createdAt"] ? new Date(fieldsUser["createdAt"]).toLocaleDateString() : ""} type="text" onChange={inputChangeUser} disabled />
                              {errorsUser && errorsUser["createdAt"] ?
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["createdAt"]}
                                </div>
                                : null}
                            </div>

                          </div>





                          {userDetailsById && userDetailsById.roleId !== 100 && <div className=' bg-white p-1 rounded-md'>
                            <label> Permission </label>
                            <Multiselect
                              options={options}
                              displayValue="name"
                              onSelect={handleSelect}
                              onRemove={handleRemove}
                              selectedValues={selectedOptions}
                              placeholder="Select options"
                            />
                            <button onClick={(e) => handlePermission(e)} className=' bg-[#7D1970] text-white p-2 rounded-md m-2'>Save</button>
                          </div>}





                          <div className="flex space-x-5  border-gray-400 ">
                            <div className="flex w-full items-center justify-center">
                              <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                                onClick={() => updateUserSubmit()}
                              >Submit</button>
                            </div>
                          </div>

                        </div>
                        {/* small device  */}
                        <div className='lg:hidden  sm:block bg-white  rounded-md'>
                          <div className='flex justify-center items-center  border-gray-400 pb-3  p-2 '>
                            <span className="text-lg  font-semibold text-gray-900">Personal Information</span>
                          </div>
                          <div className=' flex  flex-wrap gap-3 p-4'>
                            <div className=" gap-2 w-full">
                              <label className="block w-44 mx-3 text-gray-700 text-base whitespace-nowrap" for="name">Full Name:</label>
                              <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2 capitalize"
                                id="userName" name="userName"
                                value={fieldsUser && fieldsUser["userName"] ? fieldsUser["userName"] : ""} type="text" onChange={inputChangeUser} />
                              {errorsUser && errorsUser["userName"] ?
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["userName"]}
                                </div>
                                : null}
                            </div>


                            <div className="f gap-2 w-full">
                              <label className="block w-44 mx-3 text-gray-700 text-base" for="name">Mobile:</label>
                              <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                                id="mobNo" name="mobNo"
                                value={fieldsUser && fieldsUser["mobNo"] ? fieldsUser["mobNo"] : ""} type="text" onChange={inputChangeUser} />
                              {errorsUser && errorsUser["mobNo"] ?
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["mobNo"]}
                                </div>
                                : null}
                            </div>


                            <div className=" gap-2 w-full">
                              <label className="block mx-3 w-44 text-gray-700 text-base whitespace-nowrap" for="name">Creation Date:</label>
                              <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                                id="createdAt" name="createdAt"
                                value={fieldsUser && fieldsUser["createdAt"] ? new Date(fieldsUser["createdAt"]).toLocaleDateString() : ""} type="text" onChange={inputChangeUser} disabled />
                              {errorsUser && errorsUser["createdAt"] ?
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["createdAt"]}
                                </div>
                                : null}
                            </div>
                            {userDetailsById && userDetailsById.roleId !== 100 && 
                            <div className=' bg-white p-1 rounded-md w-full'>
                              <label> Permission </label>
                              <Multiselect
                                options={options}
                                displayValue="name"
                                onSelect={handleSelect}
                                onRemove={handleRemove}
                                selectedValues={selectedOptions}
                                placeholder="Select options"
                              />
                              <button onClick={(e) => handlePermission(e)} className=' bg-[#7D1970] text-white p-2 rounded-md m-2'>Save</button>
                            </div>}

                          </div>
                          <div className="flex justify-center place-items-center w-full space-x-5 mt-2 border-gray-400 mb-2">
                            <div className="flex w-full items-center justify-center">
                              <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                                onClick={() => updateUserSubmit()}
                              >Submit</button>
                            </div>
                          </div>
                        </div>

                        <div className='w-full col-span-1 p-5 space-y-2 bg-white rounded-xl lg:block hidden '>
                          <div className='flex justify-between items-centerer  border-gray-400 pb-3'>
                            <span className="text-lg text-left font-semibold text-gray-900">Payment Information </span>
                          </div>

                          <div className="flex w-full gap-2  items-center">
                            <label className="block w-44 text-gray-700 text-base whitespace-nowrap" for="name">Bank Name:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="bankName" name="bankName"
                              value={bankDetailsOfUser && bankDetailsOfUser["bankName"] ? bankDetailsOfUser["bankName"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["bankName"] ?
                              <div className="text-red-600 whitespace-nowrap text-wrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["bankName"]}
                              </div>
                              : null}
                          </div>

                          <div className="flex w-full  gap-2  items-centerer">
                            <label className="block w-44 text-gray-700 text-base whitespace-nowrap" for="name">A/C Hol. Name:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="name" name="name"
                              value={bankDetailsOfUser && bankDetailsOfUser["name"] ? bankDetailsOfUser["name"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["name"] ?
                              <div className="text-red-600 whitespace-nowrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["name"]}
                              </div>
                              : null}
                          </div>

                          <div className="flex w-full gap-2 items-center">
                            <label className="block w-44 text-gray-700 text-base whitespace-nowrap" for="name">A/C Number:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="accountNo" name="accountNo"
                              value={bankDetailsOfUser && bankDetailsOfUser["accountNo"] ? bankDetailsOfUser["accountNo"] : ""} type="number" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["accountNo"] ?
                              <div className="text-red-600 whitespace-nowrap text-wrap invalid-feedback text-[13px] w-[50px] ">
                                {errorsBank["accountNo"]}
                              </div>
                              : null}
                          </div>

                          <div className="flex w-full gap-2 items-center">
                            <label className="block w-44 text-gray-700 text-base whitespace-nowrap" for="name">IFSC Code:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="ifsc" name="ifsc"
                              value={bankDetailsOfUser && bankDetailsOfUser["ifsc"] ? bankDetailsOfUser["ifsc"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["ifsc"] ?
                              <div className="text-red-600 whitespace-nowrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["ifsc"]}
                              </div>
                              : null}
                          </div>
                          <div className="flex w-full gap-2 items-center">
                            <label className="block w-44 text-gray-700 text-base whitespace-nowrap" for="name">Upi Id:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="upiId" name="upiId"
                              value={bankDetailsOfUser && bankDetailsOfUser["upiId"] ? bankDetailsOfUser["upiId"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["upiId"] ?
                              <div className="text-red-600 whitespace-nowrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["upiId"]}
                              </div>
                              : null}
                          </div>
                          <div className="flex w-full gap-2 items-center">
                            <label className="block w-44 text-gray-700 text-base whitespace-nowrap" for="name">Google Pay No.:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="googlePay" name="googlePay"
                              value={bankDetailsOfUser && bankDetailsOfUser["googlePay"] ? bankDetailsOfUser["googlePay"] : ""} type="number" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["googlePay"] ?
                              <div className="text-red-600 whitespace-nowrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["googlePay"]}
                              </div>
                              : null}
                          </div>
                          <div className="flex w-full gap-2 items-center">
                            <label className="block w-44 text-gray-700 text-base whitespace-nowrap" for="name">Phone Pay No.:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="phonePay" name="phonePay"
                              value={bankDetailsOfUser && bankDetailsOfUser["phonePay"] ? bankDetailsOfUser["phonePay"] : ""} type="number" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["phonePay"] ?
                              <div className="text-red-600 whitespace-nowrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["phonePay"]}
                              </div>
                              : null}
                          </div>



                          <div className="flex space-x-5  border-gray-400">
                            <div className="flex w-full items-center justify-center">
                              <button className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                                onClick={() => updateBankDetailsSubmit()}
                              >Submit</button>
                            </div>
                          </div>

                        </div>

                        <div className='lg:hidden sm:block bg-white p-2 rounded-md'>
                          <div className='flex justify-center items-center  border-gray-400 pb-3'>
                            <span className="text-lg  font-semibold text-gray-900 text-[15px] text-center">Payment Information </span>
                          </div>

                          <div className=" w-full gap-2  items-center p-2">
                            <label className="block w-44 mx-2 text-gray-700 text-base whitespace-nowrap" for="name">Bank Name:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="bankName" name="bankName"
                              value={bankDetailsOfUser && bankDetailsOfUser["bankName"] ? bankDetailsOfUser["bankName"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["bankName"] ?
                              <div className="text-red-600 whitespace-nowrap mx-2 invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["bankName"]}
                              </div>
                              : null}
                          </div>

                          <div className=" w-full  gap-2  items-center p-2">
                            <label className="block w-44 mx-2 text-gray-700 text-base whitespace-nowrap" for="name">A/C Holder Name:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="name" name="name"
                              value={bankDetailsOfUser && bankDetailsOfUser["name"] ? bankDetailsOfUser["name"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["name"] ?
                              <div className="text-red-600 whitespace-nowrap mx-2 invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["name"]}
                              </div>
                              : null}
                          </div>

                          <div className=" w-full    gap-2 items-center p-2">
                            <label className="block w-44 mx-2 text-gray-700 text-base whitespace-nowrap" for="name">A/C Number:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="accountNo" name="accountNo"
                              value={bankDetailsOfUser && bankDetailsOfUser["accountNo"] ? bankDetailsOfUser["accountNo"] : ""} type="number" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["accountNo"] ?
                              <div className="text-red-600 whitespace-nowrap mx-2 invalid-feedback text-[13px] w-[50px] ">
                                {errorsBank["accountNo"]}
                              </div>
                              : null}
                          </div>

                          <div className=" w-full gap-2 items-center p-2">
                            <label className="block w-44 mx-2 text-gray-700 text-base whitespace-nowrap" for="name">IFSC Code:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="ifsc" name="ifsc"
                              value={bankDetailsOfUser && bankDetailsOfUser["ifsc"] ? bankDetailsOfUser["ifsc"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["ifsc"] ?
                              <div className="text-red-600 whitespace-nowrap mx-2 invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["ifsc"]}
                              </div>
                              : null}
                          </div>
                          <div className=" w-full gap-2 items-center p-2">
                            <label className="block w-44 mx-2 text-gray-700 text-base whitespace-nowrap" for="name">Upi Id:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="upiId" name="upiId"
                              value={bankDetailsOfUser && bankDetailsOfUser["upiId"] ? bankDetailsOfUser["upiId"] : ""} type="text" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["upiId"] ?
                              <div className="text-red-600 whitespace-nowrap mx-2 invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["upiId"]}
                              </div>
                              : null}
                          </div>
                          <div className=" w-full gap-2 items-center p-2">
                            <label className="block w-44 mx-2 text-gray-700 text-base whitespace-nowrap" for="name">Google Pay No.:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="googlePay" name="googlePay"
                              value={bankDetailsOfUser && bankDetailsOfUser["googlePay"] ? bankDetailsOfUser["googlePay"] : ""} type="number" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["googlePay"] ?
                              <div className="text-red-600 whitespace-nowrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["googlePay"]}
                              </div>
                              : null}
                          </div>
                          <div className=" w-full gap-2 items-center p-2">
                            <label className="block w-44 mx-2 text-gray-700 text-base whitespace-nowrap" for="name">Phone Pay No.:</label>
                            <input className="  w-full px-3 py-2 text-sm bg-white focus:outline-none rounded-md border mx-2"
                              id="phonePay" name="phonePay"
                              value={bankDetailsOfUser && bankDetailsOfUser["phonePay"] ? bankDetailsOfUser["phonePay"] : ""} type="number" onChange={inputChangeBank} />
                            {errorsBank && errorsBank["phonePay"] ?
                              <div className="text-red-600 whitespace-nowrap invalid-feedback text-[13px] w-[50px]">
                                {errorsBank["phonePay"]}
                              </div>
                              : null}
                          </div>
                          <div className="flex justify-center space-x-5  border-gray-400 mt-2">
                            <div className="flex justify-center place-items-center w-full items-centerer">
                              <button className="px-4 py-2 w-full font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                                onClick={() => updateBankDetailsSubmit()}
                              >Submit</button>
                            </div>
                          </div>
                        </div>

                      </div>



                      {/* Table 2 */}
                      <div className="relative m-2 overflow-x-auto p-5 border bg-white rounded-md shadow-md ">
                        <div className=' text-gray-700 text-xl whitespace-nowrap  font-bold border-b'>Transaction History</div>
                        <div className='border-b flex flex-col lg:flex-row justify-between place-items-center '>
                          <div className='w-full'>
                            <label className="block  text-gray-700 text-base mb-2" for="name">Show Data</label>
                            <select className={`w-full lg:w-auto px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none`}
                              id="size" name="size" placeholder="name"
                              value={size2} type="text"
                              onChange={(e) => handleInputSize(e)}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>

                          <div className=' flex flex-col justify-end md:flex-row md:items-end p-1 gap-5 w-full '>
                            <div className=''>
                              <div>
                                From:
                              </div>
                              <input value={fromDate2} onChange={(e) => setfromDate2(e.target.value)} type='date' className='w-full outline-none  border px-2 py-1.5 rounded-md' />
                            </div>
                            <div className=' '>
                              <div>

                                To:
                              </div>
                              <input type='date' value={toDate2} onChange={(e) => settoDate2(e.target.value)} className='w-full outline-none  border px-3 py-1.5 rounded-md' />
                            </div>
                            <button onClick={() => handleFilter2()} className='px-3 py-2  w-full lg:w-auto bg-[#f64401] text-white rounded-lg'>Search</button>
                          </div>
                        </div>
                        <div className='overflow-hidden overflow-x-auto'>
                          <table className="w-full overflow-hidden overflow-x-auto text-sm text-left text-gray-500 ">
                            <thead className="text-base text-gray-700 capitalize  bg-gray-50">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">Transaction type</th>
                                <th className="px-6 py-3">Amount</th>
                                <th className="px-6 py-3">Type</th>
                                <th className="px-6 py-3">Date</th>

                              </tr>
                            </thead>
                            <tbody>
                              {tnxList && tnxList.length > 0 ? tnxList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr key={index} className="bg-white  border-gray-300">

                                    <td className="px-6 py-2">
                                      {offset2 + index + 1}
                                    </td>
                                    <th className="px-6 py-2 font-medium  whitespace-nowrap ">
                                      {element && element?.txtype ? element?.txtype : "-"}
                                    </th>
                                    <th className="px-6 py-2 font-medium  whitespace-nowrap ">
                                      {element && element?.amount ? element?.amount : "-"}
                                    </th>  <th className="px-6 py-2 font-medium  whitespace-nowrap ">
                                      {element && element?.type ? element?.type : "-"}
                                    </th>
                                    <td className="px-6 py-2  whitespace-nowrap uppercase">
                                      {element && element.createdAt ? moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a") : "-"}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )) :
                                <tr>
                                  <td className="px-6 py-4">
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody>

                          </table>
                        </div>

                        {
                          tnxTotal && tnxTotal > size2 ? (
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={Math.ceil(tnxTotal / size2)}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick2}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage2}
                            />
                          ) : null
                        }

                      </div>


                      <div className="relative m-2 overflow-x-auto p-5 border rounded-md bg-white">
                        <div className="text-gray-700 text-xl font-bold whitespace-nowrap border-b">Bid History</div>
                        <div className="flex flex-col  md:flex-row justify-between place-items-end items-center px-3  border-b">
                          <div className="mb-4 md:mb-0  md:w-24 w-full   ">
                            <label className="block text-gray-700 text-base mb-2" htmlFor="size">Show Data</label>
                            <select
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none"
                              id="size"
                              name="size"
                              placeholder="size"
                              value={size}
                              onChange={(e) => handleInputSize2(e)}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                          <div>
                            {/* <input
                              className="w-full px-3 py-2 text-sm bg-white border border-gray-400 rounded-md focus:outline-none mt-2"
                              id="search"
                              placeholder="Search"
                              name="search"
                              value={keyWord2}
                              type="text"
                            onChange={(e) => handleInputSize2(e)}
                            />
                            {errorsGame && errorsGame["search"] && (
                              <div className="text-yellow-600 invalid-feedback">
                                {errorsGame["search"]}
                              </div>
                            )} */}
                          </div>
                          <div className="w-full md:w-auto mt-1 shadow-sm">
                            <div className="flex flex-col md:flex-row md:items-end p-1 gap-5">
                              <div className="flex flex-col">
                                <div>From:</div>
                                <input
                                  value={fromDate}
                                  onChange={(e) => setfromDate(e.target.value)}
                                  type="date"
                                  className="outline-none border px-2 py-1.5  rounded-md"
                                />
                              </div>
                              <div className="flex flex-col">
                                <div>To:</div>
                                <input
                                  value={toDate}
                                  onChange={(e) => settoDate(e.target.value)}
                                  type="date"
                                  className="outline-none border px-2 py-1.5 rounded-md"
                                />
                              </div>
                              <button
                                onClick={() => handleFilter1()}
                                className="px-3 py-2 bg-[#f64401] text-white rounded-lg"
                              >
                                Search
                              </button>
                            </div>


                          </div>
                        </div>
                        <div className=' overflow-hidden overflow-x-auto'>
                          <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-base text-gray-700 capitalize bg-gray-50">
                              <tr>
                                <th className="px-2 py-3">S.No.</th>
                                <th className="px-2 py-3">Game Name</th>
                                {/* <th className="px-2 py-3">Game Open Time</th> */}
                                {/* <th className="px-2 py-3">Game Close Time</th> */}
                                <th className="px-2 py-3">Game Type</th>
                                <th className="px-2 py-3">Date</th>
                                <th className="px-2 py-3">Bet Number</th>
                                <th className="px-2 py-3">Point</th>
                                <th className="px-2 py-3">Bet Type</th>
                                <th className="px-2 py-3">Amount</th>
                                <th className="px-2 py-3">Result</th>
                                <th className="px-2 py-3 text-right">Profit/Loss</th>
                              </tr>
                            </thead>
                            <tbody>
                              {betsListdata && betsListdata.length > 0 ? (
                                betsListdata.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr key={index} className="bg-white  capitalize text-left">
                                      <td className="px-2 py-2">{offset + index + 1}</td>
                                      <th className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap">
                                        {element && element?.gameId ? element?.gameId?.name : "-"}
                                      </th>
                                      {/* <td className="px-2 py-2">
                                        {element && element?.gameId && element?.gameId?.openTime
                                          ? new Date(element?.gameId?.openTime).toLocaleTimeString()
                                          : "-"}
                                      </td>
                                      <td className="px-2 py-2">
                                        {element && element?.gameId && element?.gameId?.closingTime
                                          ? new Date(element?.gameId?.closingTime).toLocaleTimeString()
                                          : "-"}
                                      </td> */}
                                      <td className="px-2 py-2">
                                        {element && element?.marketId && element?.marketId?.name
                                          ? element?.marketId?.name
                                          : "-"}
                                      </td>
                                      <td className="px-2 py-2 whitespace-nowrap  uppercase">
                                        {element && element.createdAt
                                          ? moment(new Date(parseInt(element.createdAt)))
                                            .utcOffset("+05:30")
                                            .format("DD-MM-YYYY hh:mm a")
                                          : "-"}
                                      </td>
                                      <td className="px-2 py-2">
                                        <div className=' '>

                                          {element && element.betNumber ? element.betNumber : "-"}
                                        </div>
                                      </td>
                                      <td className="px-2 py-2">
                                        {element && element?.marketId?.point ? element?.marketId?.point : "-"}
                                      </td>
                                      <td className="px-2 py-2">
                                        {element && element.isOpen ? "Open" : "Close"}
                                      </td>
                                      <td className="px-2 py-2">
                                        {element && element.amount ? element.amount : "--"}
                                      </td>
                                      <td className="px-2 py-2">
                                        {element?.betStatus == 0 && (
                                          <div className="text-yellow-500">Pending</div>
                                        )}
                                        {element?.betStatus == 1 && (
                                          <div className="text-green-500">Win</div>
                                        )}
                                        {element?.betStatus == 2 && (
                                          <div className="text-red-500">Loss</div>
                                        )}
                                      </td>
                                      <td className="px-2 py-2 text-right">
                                        {element?.betStatus == 1 && (
                                          <div className="text-green-500">{element?.winAmount || "--"}</div>
                                        )}
                                        {element?.betStatus == 2 && (
                                          <div className="text-red-500">{element?.lossAmount || "--"}</div>
                                        )}
                                        {element?.betStatus == 0 && <div className="text-yellow-500">--</div>}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td className="px-2 py-1">No data found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {
                          betsListdata && betsListtotal > size ? (
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={Math.ceil(betsListtotal / size)}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick1}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                          ) : null
                        }
                      </div>
                      <div className='client payment list'>
                        <ClientPaymentList userId={userId} />
                      </div>
                      {/* {
                        tnxList && tnxTotal > size ? (
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={Math.ceil(tnxTotal / size)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick2}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                            forcePage={currentPage2}
                          />
                        ) : null
                      } */}



                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div >
      </div >
      <ViewUserModal
        viewModal={viewModal}
        rowData={rowData}
        handleViewHideModal={handleViewHideModal}
        handleChange={handleChange}
        handleFund={handleFund}
        errorsGame={errorsGame}
      />
    </>
  );
};

export default Dashboard;
