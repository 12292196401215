import React, { useEffect, useRef, useState } from "react";
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from "react-redux";
export default function CreateGameModal(props) {
  let { createModal, handleCreateHideModal, resetKey, setResetKey, setMarketSelected, inputChangeCreate, addref, fieldsGame, marketSelected, errorsGame, createGameSubmit, handleFile, setDaysSelected, DaysSelected, onSelect2, onRemove2, allMarket, onSelect, onRemove } = props;

  let selector = useSelector(state => state)
  let { users } = selector ? selector : {};
  let { fileData, loading } = users ? users : {};

  const minutes = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const seconds = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );

  const timeOptions = minutes.flatMap((min) =>
    seconds.map((sec) => `${min}:${sec}`)
  );

  useEffect(() => {
    return () => {
      setMarketSelected([]);
      setResetKey((prevKey) => prevKey + 1);
    };
  }, [createModal]);

  let [options, setoptions] = useState([])
  const [options2, setOptions2] = useState([
    { name: 'Sunday', label: 'Sunday' },
    { name: 'Monday', label: 'Monday' },
    { name: 'Tuesday', label: 'Tuesday' },
    { name: 'Wednesday', label: 'Wednesday' },
    { name: 'Thursday', label: 'Thursday' },
    { name: 'Friday', label: 'Friday' },
    { name: 'Saturday', label: 'Saturday' }
  ]);
  useEffect(() => {
    let allMarketdata = allMarket && allMarket.length > 0 && allMarket.filter((e) => (e.shortName !== "KolkataSINGLE" && e.shortName !== "KolkataSinglePanna"));

    setoptions(allMarketdata)

  }, [allMarket])

  return (
 <div className={createModal ? `fixed inset-0 z-50 flex items-center justify-center w-full p-2 overflow-hidden overflow-y-auto  main-modal h-100 animated fadeIn faster` : 'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="z-50  w-[50rem]    mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container ">
        <div className="px-6 py-4 text-left modal-content">
          {/*Title*/}
          <div className="flex items-center bg-[#f64401] text-white justify-between px-1 py-2 rounded-lg">
            <p className="text-2xl font-bold">Create Game</p>
            <div className="z-50 cursor-pointer modal-close">
              <svg onClick={() => handleCreateHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form autoComplete="off" className=" relative">

            <div className=" grid grid-cols-1 lg:grid-cols-3  gap-1">

              <div className="relative mt-1 w-full shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="name">Game Name :</label>
                <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsGame && !errorsGame["name"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="name" name="name" placeholder="Name"
                  // value={fieldsGame.name}
                  value={fieldsGame && fieldsGame["name"] ? fieldsGame["name"] : ""}

                  type="text" onChange={inputChangeCreate} />
                {errorsGame && errorsGame["name"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsGame["name"]}
                  </div>
                  : null}
              </div>


              <div className="w-full">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">Short Game Name :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsGame && !errorsGame["shortName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="shortName" name="shortName" placeholder="Short Name" value={fieldsGame && fieldsGame["shortName"] ? fieldsGame["shortName"] : ""} type="text" onChange={inputChangeCreate} />
                  {errorsGame && errorsGame["shortName"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsGame["shortName"]}
                    </div>
                    : null}
                </div>
              </div>


              <div className="w-full">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">Game Name Hindi  :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsGame && !errorsGame["shortName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="nameHindi" name="nameHindi" placeholder="Hindi Name" value={fieldsGame && fieldsGame["nameHindi"] ? fieldsGame["nameHindi"] : ""} type="text" onChange={inputChangeCreate} />
                  {errorsGame && errorsGame["nameHindi"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsGame["nameHindi"]}
                    </div>
                    : null}
                </div>
              </div>

            </div>

            <div className="  grid grid-cols-1 lg:grid-cols-2  gap-1">



              <div className=" w-full" >
                <div className="relative mt-1   w-full shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">Open Result Time(HH:MM) :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsGame && !errorsGame["value"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="openTime" name="openTime" placeholder="Open Time" value={fieldsGame && fieldsGame["openTime"] ? fieldsGame["openTime"] : ""} type="time" onChange={inputChangeCreate} />
                  {errorsGame && errorsGame["openTime"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsGame["openTime"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="w-full" >
                <div className="relative mt-1 flex-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">Close Result Time(HH:MM):</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsGame && !errorsGame["value"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="closingTime" name="closingTime" placeholder="Closing Time" value={fieldsGame && fieldsGame["closingTime"] ? fieldsGame["closingTime"] : ""} type="time" onChange={inputChangeCreate} />
                  {errorsGame && errorsGame["closingTime"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsGame["closingTime"]}
                    </div>
                    : null}
                </div>
              </div>
            </div>
            <div className=" flex  gap-4">


            </div>
            <div className=" flex gap-1">
              <div className=" w-full" >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">Priority :</label>
                  <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsGame && !errorsGame["value"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="priority" name="priority" placeholder="Priority" value={fieldsGame && fieldsGame["priority"] ? fieldsGame["priority"] : ""} type="number" onChange={inputChangeCreate} />
                  {errorsGame && errorsGame["priority"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsGame["priority"]}
                    </div>
                    : null}
                </div>
              </div>



              <div className=" w-full hidden" >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="gamename">BackgroundColor :</label>
                  <input className={`w-full  px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsGame && !errorsGame["value"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="backgroundColor" name="backgroundColor" placeholder="backgroundColor" value={fieldsGame && fieldsGame["backgroundColor"] ? fieldsGame["backgroundColor"] : ""} type="text" onChange={inputChangeCreate} />
                  {errorsGame && errorsGame["backgroundColor"] ?
                    <div className="text-red-600 invalid-feedback">
                      {errorsGame["backgroundColor"]}
                    </div>
                    : null}
                </div>
              </div>


            </div>



            <div className="grid grid-cols-1 lg:grid-cols-2 gap-1">
              <div className="w-full my-1">
                Market
                <Multiselect
                  key={resetKey}
                  options={options}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  placeholder="Select Market"
                  selectedValues={marketSelected ? marketSelected : null}

                />
                {errorsGame && errorsGame["selectedmarketData"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsGame["selectedmarketData"]}
                  </div>
                  : null}
              </div>


              <div className="w-full my-1">
                Day Off
                <Multiselect
                  key={resetKey}
                  options={options2}
                  onSelect={onSelect2}
                  onRemove={onRemove2}
                  placeholder="Select Day"
                  displayValue="name"
                  selectedValues={DaysSelected ? DaysSelected : null}

                />
                {errorsGame && errorsGame["selectedmarketData"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsGame["selectedmarketData"]}
                  </div>
                  : null}
              </div>

            </div>



            {loading &&
              <div className=" absolute bottom-28  left-5" role="status">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>

            }
            <div className="w-64 mx-auto mt-4 sm:w-72">
              <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#f64401] border border-[#f64401] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#f64401] focus:shadow-outline-yellow active:bg-[#f64401]" type="button" onClick={createGameSubmit}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
}
