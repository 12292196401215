import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './AppSettingManagement.json'
import { alertActions, appSettingActions, userActions } from '../../_actions';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';
import { FaRegSave } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import Table from '../../components/Atoms/Table/Table';


const AppSettingManagement = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(100);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [value, setValue] = useState('');
  const { appSettingList, total, loading } = useSelector(state => state.appSettings);
  const selector = useSelector(state => state);

  const [loading1, setLoading] = useState(false)


  const [initialState, setInitialState] = useState({
    _id: '',
    name: '',
    key: '',
    value: '',
  });

  const [videoLink, setVideoLink] = useState('')


  // console.log(initialState)

  useEffect(() => {
    let appsettingReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(appSettingActions.getAppSettingList(appsettingReq));


  }, []);


  const handleCreateOpenModal = () => {
    setCreateModal(true);
  }
  const savedatadata = (e) => {
    console.log(e)
  }



  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  }

  // const handleUpdateOpenModal = (data) => {
  //   setFieldsUpdate(data);
  //   setUpdateModal(true);
  // }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    console.log(e)
    // const { name, value } = e.target;
    // setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    // setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsUpdate;
      dispatch(appSettingActions.updateAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setUpdateModal, appsettingPageRefresh));
    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };


  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsAppSetting;
      dispatch(appSettingActions.createAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setCreateModal, setFieldsAppSetting, appsettingPageRefresh));
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsAppSetting(errors);
    return formIsValid;
  };


  const disableAppSetting = (data) => {
    let appsettingIdReq = {
      "id": data._id,
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    confirmAlert({

      title: 'Confirm to disable AppSetting?',
      message: `Are you sure to disable ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(appSettingActions.updateAppSettingStatus(appsettingIdReq, appsettingPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  // const deleteAppSetting = (data) => {
  //   let appsettingIdReq = {
  //     "id": data._id,
  //   }

  //   let appsettingPageRefresh = {
  //     "keyWord": "",
  //     "pageNo": pageNo,
  //     "size": size
  //   }

  //   confirmAlert({

  //     title: 'Confirm to Delete?',
  //     message: `Are you sure you want  to delete ${data.name}?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => dispatch(appSettingActions.deleteAppSetting(appsettingIdReq, appsettingPageRefresh))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }


  const handleFile = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
    } else {
      console.log("No File To Upload!");
    }
  };


  const handleFile2 = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadVideo(event));
    } else {
      console.log("No File To Upload!");
    }
  };




  const [state1, setstate1] = useState("")
  const [state2, setstate2] = useState("")
  const [state3, setstate3] = useState("")
  const [state4, setstate4] = useState("")
  const [state5, setstate5] = useState("")
  const [state10, setstate10] = useState("")
  const [state99, setstate99] = useState("")
  const [state1000, setstate1000] = useState("")
  const [state88, setstate88] = useState("")

  const [stateVideo1, setstateVideo1] = useState("")
  const [stateVideo2, setstateVideo2] = useState("")
  const [stateVideo3, setstateVideo3] = useState("")

  const [howToAddFundVideo, setHowToAddVideo] = useState("")
  const [withdrawFundVideo, setWithDrawFundVideo] = useState("")
  const [playGameVideo, setPlayGameVideo] = useState("")

  console.log('howToAddFundVideohowToAddFundVideo', howToAddFundVideo)





  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {
        if (element.key === "how_to_add_fund") {
          setHowToAddVideo(element?.value)
        }
        if (element.key === "how_to_withdrewal_fund") {
          setWithDrawFundVideo(element.value);
        }
        if (element.key === "how_to_play_open_jodi_panna") {
          setPlayGameVideo(element.value);
        }
        if (element.key === "Welcome_Bonus") {
          setstate88(element.value);
        }
        if (element.key === "minimumWithdrawlLimit") {
          setstate1000(element.value);
        }
        if (element.key === "YoutubeLink") {
          setstate1(element.value);
        }
        if (element.key === "Update_Link") {
          setstate99(element.value);
        }
        if (element.key === "Email") {
          setstate4(element.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(element.value);
        }
        if (element.key === "MobileNumber") {
          setstate2(element.value);
        }
        if (element.key === "Version") {
          setstate5(element.value);
        }
        if (element.key === "HowToPlay") {
          setValue(element.value);
        }
        if (element.key === "UPI_IMAGE") {
          setstate10(element.value);
        }
      });
    }
  }, [appSettingList]);

  const saveInfo = async (e) => {
    console.log(e)
    let dataofvalue;
    if (e.key === "UPI_IMAGE") {
      let { users } = selector ? selector : {}
      let { fileData } = users ? users : {}
      dataofvalue = fileData ? fileData : state10;
    }


    if (e.key === "how_to_add_fund") {
      dataofvalue = howToAddFundVideo;
    }



    if (e.key === "how_to_withdrewal_fund") {

      dataofvalue = withdrawFundVideo;
    }

    if (e.key === "how_to_play_open_jodi_panna") {

      dataofvalue = playGameVideo;
    }


    // if (e.key === "how_to_add_fund") {
    //   dataofvalue = stateVideo1;
    // }

    // if (e.key === "how_to_withdrewal_fund") {
    //   dataofvalue = stateVideo2;
    // }
    // if (e.key === "how_to_play_open_jodi_panna") {
    //   dataofvalue = stateVideo3;
    // }





    if (e.key === "Welcome_Bonus") {
      dataofvalue = state88;
    }

    if (e.key === "minimumWithdrawlLimit") {
      dataofvalue = state1000;
    }
    if (e.key === "Update_Link") {
      dataofvalue = state99;
    }
    if (e.key === "Version") {
      dataofvalue = state5;
    }
    if (e.key === "YoutubeLink") {
      dataofvalue = state1;
    }
    if (e.key === "Email") {
      dataofvalue = state4;
    }
    if (e.key === "WhatsApp") {
      dataofvalue = state3;
    }
    if (e.key === "MobileNumber") {
      dataofvalue = state2;
    }
    if (e.key === "HowToPlay") {
      dataofvalue = value;
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id
    }
    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh))


  }
  let handlemobile = (e) => {
    e.preventDefault();
    if (e.target.value.length < 11) {
      setstate2(e.target.value)
    } else {
      return;
    }

  }


  let { users } = selector ? selector : {}
  let { fileData } = users ? users : {}
  let { fileData2 } = users ? users : {}

  const handleUploadVideos = (e, element) => {
    const file = e.target.files[0];
    if (!file) return;


    const formData = new FormData();
    formData.append('image', file);
    setLoading(true)
    userActions.uploadVideosPromise(formData, element).then((res) => {
      if (res) {
        setHowToAddVideo(() => res.data.imageURL)
        setLoading(false)
        console.log('res.data.imageUrlres.data.imageUrl', res?.data?.imageURL)
      }
    }).catch((err) => {
      dispatch(alertActions.error(err))
    })
  };

  const handleWithDrawFundVideo = (e, element) => {
    const file = e.target.files[0];
    if (!file) return;


    const formData = new FormData();
    formData.append('image', file);
    setLoading(true)
    userActions.uploadVideosPromise(formData, element).then((res) => {
      if (res) {
        setWithDrawFundVideo(() => res.data.imageURL)
        setLoading(false)
        // console.log('res.data.imageUrlres.data.imageUrl', res?.data?.imageURL)
      }
    }).catch((err) => {
      dispatch(alertActions.error(err))
    })
  };

  const handlePlayGameVideo = (e, element) => {
    const file = e.target.files[0];
    if (!file) return;


    const formData = new FormData();
    formData.append('image', file);
    setLoading(true)
    userActions.uploadVideosPromise(formData, element).then((res) => {
      if (res) {
        setPlayGameVideo(() => res.data.imageURL)
        setLoading(false)
        // console.log('res.data.imageUrlres.data.imageUrl', res?.data?.imageURL)
      }
    }).catch((err) => {
      dispatch(alertActions.error(err))
    })
  };


  return (
    <>
      <Loader loading={selector?.users?.loading} />
      <Loader loading={loading1} />

      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between ">
                        <div>
                          <p className='text-2xl font-bold pb-4'>App Setting Management</p>
                        </div>
                      </sectiion>

                      <div>
                        <div className="relative border shadow grid md:grid-cols-2 grid-cols-1 bg-white p-4 rounded-md">




                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "minimumWithdrawlLimit") {
                                    return (


                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-semibold text-gray-900 capitalize"> min Withdrawl Limit</label>
                                          <input value={state1000} onChange={(e) => setstate1000(e.target.value)} type="text" id="text" class="bg-gray-50 capitalize  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>



                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "Welcome_Bonus") {
                                    return (


                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-semibold text-gray-900 ">{element && element.name} </label>
                                          <input value={state88} onChange={(e) => setstate88(e.target.value)} type="text" id="text" class="bg-gray-50 capitalize  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>





                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "Update_Link") {
                                    // console.log("Update_LinkUpdate_Link",Update_Link)
                                    return (


                                      <form class="flex   w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-semibold text-gray-900 ">{element && element.name || "asdsada"} </label>
                                          <input value={state99} onChange={(e) => setstate99(e.target.value)} type="text" id="text" class="bg-gray-50 capitalize  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>











                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "Version") {
                                    return (

                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-semibold text-gray-900 ">{element && element.name} </label>
                                          <input value={state5} onChange={(e) => setstate5(e.target.value)} type="text" id="text" class="bg-gray-50 capitalize  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>













                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "MobileNumber") {
                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className=' w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-semibold text-gray-900 ">{element && element.name} </label>
                                          <input value={state2} onChange={(e) => handlemobile(e)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>



                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "WhatsApp") {
                                    return (


                                      <form class="flex w-full justify-start items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-semibold text-gray-900 ">{element && element.name} </label>
                                          <input value={state3} onChange={(e) => setstate3(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>




                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "Email") {
                                    return (


                                      <form class="flex w-full  justify-start items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-semibold  text-gray-900 ">{element && element.name} </label>
                                          <input value={state4} onChange={(e) => setstate4(e.target.value)} type="text" id="text" class="bg-gray-50  border border-gray-300 text-gray-900 capitalize text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>



                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>











                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "YoutubeLink") {
                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-semibold text-gray-900 ">{element && element.name} </label>
                                          <input value={state1} onChange={(e) => setstate1(e.target.value)} type="text" id="text" class="bg-gray-50 pe-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }

                                }) : null
                            }
                          </div>







                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "UPI_IMAGE") {
                                    return (
                                      <form className="flex justify-start w-full items-center mt-2">
                                        <div className='w-full relative'>
                                          <label htmlFor="image" className="block mb-1 text-sm font-semibold text-gray-900">
                                            UPI (QR) Image
                                          </label>
                                          <img src={fileData ? fileData : state10} className='w-40 h-40 mb-1' alt='qr' />


                                          {/* Image Input */}
                                          <input
                                            type="file"
                                            id="image"
                                            accept="image/*"
                                            onChange={(e) => handleFile(e)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            required
                                          />

                                          {/* Save Button */}
                                          <FaRegSave
                                            title='Save'
                                            onClick={(e) => saveInfo(element, e)}
                                            className='text-[#F64401] absolute top-[12.5rem] right-2 text-xl cursor-pointer'
                                          />
                                        </div>
                                      </form>
                                    )
                                  }

                                }) : null
                            }
                          </div>














                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "how_to_add_fund") {
                                    return (
                                      <form className="flex justify-start w-full items-center mt-2">
                                        <div className='w-full relative'>
                                          <label htmlFor="video" className="block mb-1 text-sm font-semibold text-gray-900 capitalize">
                                            {element && element.name}
                                          </label>
                                          <div className='flex justify-between place-items-center gap-2 mb-1'>
                                            {howToAddFundVideo && <video key={howToAddFundVideo} className="w-40 h-40" controls>
                                              <source src={String(howToAddFundVideo)} />
                                              Your browser does not support the video tag.
                                            </video>}

                                          </div>





                                          {/* Image Input */}
                                          <input
                                            type="file"
                                            id="video"
                                            accept="video/*"
                                            onChange={(e) => handleUploadVideos(e, element)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            required
                                          />

                                          {/* Save Button */}
                                          <FaRegSave
                                            title='Save'
                                            onClick={(e) => saveInfo(element, e)}
                                            className='text-[#F64401] absolute top-[12.5rem] right-2 text-xl cursor-pointer'
                                          />
                                        </div>
                                      </form>
                                    )
                                  }

                                }) : null
                            }
                          </div>



                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "how_to_withdrewal_fund") {
                                    return (
                                      <form className="flex justify-start w-full items-center mt-2">

                                        <div className='w-full relative mb-1'>
                                          <label htmlFor="video" className="block mb-1 text-sm font-semibold text-gray-900 capitalize">
                                            how to withdraw fund
                                          </label>
                                          {withdrawFundVideo && <video key={withdrawFundVideo} className="w-40 h-40" controls>
                                            <source src={String(withdrawFundVideo)} />
                                            Your browser does not support the video tag.
                                          </video>}



                                          {/* Image Input */}
                                          <input
                                            type="file"
                                            id="video"
                                            accept="video/*"
                                            onChange={(e) => handleWithDrawFundVideo(e, element)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            required
                                          />

                                          {/* Save Button */}
                                          <FaRegSave
                                            title='Save'
                                            onClick={(e) => saveInfo(element, e)}
                                            className='text-[#F64401] absolute top-[12.5rem] right-2 text-xl cursor-pointer'
                                          />
                                        </div>
                                      </form>
                                    )
                                  }

                                }) : null
                            }
                          </div>





                          <div className=' flex-1 flex  p-4 items-center flex-col w-full  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "how_to_play_open_jodi_panna") {
                                    return (
                                      <form className="flex justify-start w-full items-center mt-2">
                                        <div className='w-full relative'>
                                          <label htmlFor="video" className="block mb-1 text-sm font-semibold text-gray-900">
                                            {/* {element && element.name} */}
                                            How to play game
                                          </label>
                                          {playGameVideo && <video key={playGameVideo} className="w-40 h-40" controls>
                                            <source src={String(playGameVideo)} />
                                            Your browser does not support the video tag.
                                          </video>}




                                          {/* Image Input */}
                                          <input
                                            type="file"
                                            id="video"
                                            accept="video/*"
                                            onChange={(e) => handlePlayGameVideo(e, element)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            required
                                          />

                                          {/* Save Button */}
                                          <FaRegSave
                                            title='Save'
                                            onClick={(e) => saveInfo(element, e)}
                                            className='text-[#F64401] absolute top-[12.5rem] right-2 text-xl cursor-pointer'
                                          />
                                        </div>
                                      </form>
                                    )
                                  }

                                }) : null
                            }
                          </div>





                          <div className=' flex-1 py-4 '>
                            {appSettingList && appSettingList.length > 0 ? (
                              appSettingList.map((element, index) => {
                                if (element && element.key && element.key === "HowToPlay") {
                                  return (<div className=''>
                                    <div className=' mb-1 font-semibold'> How To Play</div>
                                    <ReactQuill className='h-96  w-full   md:pb-14 pb-20' theme="snow" value={value} onChange={setValue} />
                                    <button className='bg-[#f64401] text-xl  text-white w-full rounded-md p-1' onClick={(e) => saveInfo(element, e)}> Save</button>
                                  </div>
                                  )
                                }
                              })
                            ) : null}



                          </div>

                        </div>
                      </div>



                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>





      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />


    </>
  );
};

export default AppSettingManagement;
