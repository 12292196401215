import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";

export default function UpdateGameModal(props) {
  let {
    updateModal,
    handleUpdateHideModal,
    addref2,
    inputChangeUpdate,
    fieldsUpdate,
    errorsUpdate,
    updateGameSubmit,
    handleFile,
    allMarket,
    onSelect,
    onRemove,
    marketSelected,
    setDaysSelected, DaysSelected, onSelect2, onRemove2
  } = props;

  let selector = useSelector((state) => state);
  let { users, games } = selector ? selector : {};
  let { loading } = games ? games : {};
  let { fileData } = users ? users : {};
  let [options, setoptions] = useState([]);
  const [options2, setOptions2] = useState([
    { name: 'Sunday', label: 'Sunday' },
    { name: 'Monday', label: 'Monday' },
    { name: 'Tuesday', label: 'Tuesday' },
    { name: 'Wednesday', label: 'Wednesday' },
    { name: 'Thursday', label: 'Thursday' },
    { name: 'Friday', label: 'Friday' },
    { name: 'Saturday', label: 'Saturday' }
  ]);
  useEffect(() => {
    let allMarketdata =
      allMarket &&
      allMarket.length > 0 &&
      allMarket.filter(
        (e) =>
          e.shortName !== "KolkataSINGLE" &&
          e.shortName !== "KolkataSinglePanna"
      );

    setoptions(allMarketdata);
  }, [allMarket]);

  const minutes = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const seconds = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );


  console.log("fieldsUpdate", fieldsUpdate)


  return (
    // <Modal
    //   isOpen={updateModal}
    // >

    <div
      className={
        updateModal
          ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster`
          : "hidden"
      }
      style={{ background: "rgba(0,0,0,.7)" }}
    >
      <div className="z-50 w-[40rem] mx-auto overflow-y-auto bg-white border  rounded shadow-lg modal-container md:max-w-xl">
        <div className="px-6 py-4 text-left modal-content">
          <Loader loading={loading} />
          {/*Title*/}
          <div className="flex items-center justify-between px-1 py-2 bg-[#F64401] rounded-lg text-white">
            <p className="text-2xl font-bold">Edit Review</p>
            <div className="z-50 cursor-pointer modal-close">
              <svg
                onClick={() => handleUpdateHideModal()}
                className="text-white fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                height={18}
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form
            autoComplete="off "
            className=" h-96 md:h-full  overflow-scroll md:overflow-hidden"
          >
            {/* <div className=" w-full flex gap-3 "> */}
            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4">
              <div className="relative mt-1 w-full shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" >
                  Name
                  :</label>
                <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["shortName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="randomName" name="randomName" placeholder="Name To Show"
                  value={fieldsUpdate && fieldsUpdate["randomName"] ? fieldsUpdate["randomName"] : ""}

                  type="text" onChange={inputChangeUpdate} />
                {errorsUpdate && errorsUpdate["randomName"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUpdate["randomName"]}
                  </div>
                  : null}
              </div>



              {/* </div> */}

              <div className="relative mt-1 w-full shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" >
                  Rating (*)
                  :</label>
                <input className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["shortName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="stars" name="stars" placeholder="Stars"
                  value={fieldsUpdate && fieldsUpdate["stars"] ? fieldsUpdate["stars"] : ""}

                  type="text" onChange={inputChangeUpdate} />
                {errorsUpdate && errorsUpdate["stars"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUpdate["stars"]}
                  </div>
                  : null}
              </div>
            </div>
            <div className="relative w-full mt-1 shadow-sm">
              <label class="block text-gray-700 text-base font-bold mb-2">
                Review
              </label>
              <textarea
                rows={5}
                className={`w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["content"]
                  ? "border  placeholder-gray-500"
                  : "border border-opacity-100 border-red-500 "
                  }`}
                id="content"
                name="content"
                placeholder="content"
                value={
                  fieldsUpdate && fieldsUpdate["content"]
                    ? fieldsUpdate["content"]
                    : ""
                }
                type="text"
                onChange={inputChangeUpdate}
              />
              {errorsUpdate && errorsUpdate["content"] ? (
                <div className="text-red-600 invalid-feedback">
                  {errorsUpdate["content"]}
                </div>
              ) : null}
            </div>







            <div className="w-64 mx-auto mt-4 sm:w-72">
              <button
                className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#F64401] border border-[#F64401] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#F64401] focus:shadow-outline-yellow active:bg-[#F64401]"
                type="button"
                onClick={updateGameSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    // </Modal>
  );
}
