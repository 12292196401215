import { gamesConstants } from "../_constants";
import { alertActions } from "./alert.actions";
import {
  APIcallFunction,
  headerForPublicAPI,
  logoutFunction,
  headerForPrivateAPI,
} from "../_helpers";
import { walletActions } from "./wallet.actions";
export const gamesActions = {
  createGames,
  createReview,
  updatereview,

  getGamesList,
  getAllGames,
  updateGames,
  updateGamesStatus,
  deleteGames,
  approvePaymentByAdmin,
  approveWithdrawByAdmin,
  saveResultAdmin,
  getWinnerListByBetNumber,
  resultDeclare,
  getAllMarket,
  getResultListAdmin,
  getAllUser,
  marketNumberInSingle,
  marketNumberThreeDigit,
  rollbackResultDeclare,
  getreview,
  deletereview,
};


function updatereview(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/updatereview",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.NUMBER_DELETE_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.NUMBER_DELETE_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.NUMBER_DELETE_FAILURE, error };
  }
}

function deletereview(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/deletereview",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.NUMBER_DELETE_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.NUMBER_DELETE_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.NUMBER_DELETE_FAILURE, error };
  }
}







function getreview(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getreview",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_REVIEW_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_REVIEW_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_REVIEW_FAILURE, error };
  }
}










function rollbackResultDeclare(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/rollbackResultDeclare",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(gamesActions.getResultListAdmin(pagination));
        dispatch(alertActions.success("Rollback Successful"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.ROLL_BACK_BACK_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.ROLL_BACK_BACK_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.ROLL_BACK_BACK_FAILURE, error };
  }
}
function marketNumberInSingle(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/marketNumberInSingle",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.NUMBER_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.NUMBER_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.NUMBER_FAILURE, error };
  }
}
function marketNumberThreeDigit(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/marketNumberThreeDigit",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.NUMBER_ARRAY_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.NUMBER_ARRAY_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.NUMBER_ARRAY_FAILURE, error };
  }
}
function getAllUser(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getAllUser",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_ALL_USER_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_ALL_USER_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_ALL_USER_FAILURE, error };
  }
}

function getResultListAdmin(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getResultListAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_RESULT_LIST_ADMIN_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_RESULT_LIST_ADMIN_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_RESULT_LIST_ADMIN_FAILURE, error };
  }
}

function getAllMarket(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getAllMarket",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_ALL_MARKETS_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_ALL_MARKETS_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_ALL_MARKETS_FAILURE, error };
  }
}

function resultDeclare(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/resultDeclare",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(alertActions.success("Result Declared"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.RESULT_BY_ADMIN_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.RESULT_BY_ADMIN_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.RESULT_BY_ADMIN_FAILURE, error };
  }
}
function getWinnerListByBetNumber(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getWinnerListByBetNumber",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        // dispatch(gamesActions.getResultListAdmin(pagination))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_WINNER_LIST_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_WINNER_LIST_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_WINNER_LIST_FAILURE, error };
  }
}

function saveResultAdmin(data, pagination, ShowWinnerList2) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/saveResultAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(alertActions.success("Created"));
        ShowWinnerList2();
        dispatch(getResultListAdmin(pagination));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.SAVE_ADMIN_RESULT_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.SAVE_ADMIN_RESULT_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.SAVE_ADMIN_RESULT_FAILURE, error };
  }
}

function approveWithdrawByAdmin(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/approveWithdrawByAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(alertActions.success("Updated"));
        dispatch(walletActions.getPaymentListForAdmin(pagination));
        // setCreateModal(false);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_PAYMENT_ADMIN1_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_PAYMENT_ADMIN1_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_PAYMENT_ADMIN1_FAILURE, error };
  }
}

function approvePaymentByAdmin(data, pagination) {
  console.log(data);
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/approvePaymentByAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        {
          data.status == 1
            ? dispatch(alertActions.success("Approved Successfully"))
            : dispatch(alertActions.error("Rejected Successfully"));
        }
        dispatch(walletActions.getPaymentListForAdmin(pagination));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_PAYMENT_ADMIN1_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_PAYMENT_ADMIN1_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_PAYMENT_ADMIN1_FAILURE, error };
  }
}

function createReview(data, gamePageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/createReview",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));

        dispatch(getreview(gamePageRefresh));
        // setCreateModal(false);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.CREATE_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.CREATE_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.CREATE_GAMES_FAILURE, error };
  }
}

function createGames(data, gamePageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/createGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));

        dispatch(getAllGames(gamePageRefresh));
        // setCreateModal(false);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.CREATE_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.CREATE_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.CREATE_GAMES_FAILURE, error };
  }
}
function getGamesList(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getGamesList",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_GAMES_LIST_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_GAMES_LIST_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_GAMES_LIST_FAILURE, error };
  }
}

function updateGames(data, setUpdateModal, gamePageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/updateGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(getreview(gamePageRefresh));
        setUpdateModal(false);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.UPDATE_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.UPDATE_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.UPDATE_GAMES_FAILURE, error };
  }
}

function updateGamesStatus(data, gamePageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/updateGamesStatus",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(getAllGames(gamePageRefresh));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.UPDATE_GAMES_STATUS_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.UPDATE_GAMES_STATUS_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.UPDATE_GAMES_STATUS_FAILURE, error };
  }
}

function deleteGames(data, gamePageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/deleteGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(getAllGames(gamePageRefresh));
        dispatch(alertActions.success("Game deleted successfully"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.DELETE_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.DELETE_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.DELETE_GAMES_FAILURE, error };
  }
}

function getAllGames(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getAllGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: gamesConstants.GET_ALL_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: gamesConstants.GET_ALL_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: gamesConstants.GET_ALL_GAMES_FAILURE, error };
  }
}
