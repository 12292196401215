import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  gamesActions,
  marketActions,
  userActions,
  walletActions,
} from "../../../../_actions";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DashboardJSON from "../../MarketManagement.json";
import Loader from "../../../../components/Loader/Loader";
import { IoIosCloseCircle } from "react-icons/io";

const EditMarket = () => {
  const dispatch = useDispatch();
  const { marketId } = useParams();
  const navigate = useNavigate();
  let selector = useSelector((state) => state);
  const { marketDataIdWise } = useSelector((state) => state.markets);
  const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const [fieldsUpdateMarket, setFieldsUpdateMarket] = useState({});
  const [errorsUpdateMarket, setErrorsUpdateMarket] = useState({});
  const [size, setSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    let gameReq = {
      marketId: marketId,
    };
    dispatch(marketActions.getMarketByMarketIdForAdmin(gameReq));
  }, []);

  useEffect(() => {
    dispatch(userActions.clearImage());
    if (marketDataIdWise) {
      setFieldsUpdateMarket(marketDataIdWise);
    }
  }, [marketDataIdWise]);

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdateMarket((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdateMarket((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateMarketSubmit = (e) => {
    e.preventDefault();
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    console.log(fileData);

    if (handleValidationUpdateMarket()) {
      const { name, point, shortName, _id, min, max } = fieldsUpdateMarket;
      dispatch(
        marketActions.updateMarket({
          name: name && name.trim(),
          image:
            fileData ||
            fieldsUpdateMarket.image,
          shortName: shortName && shortName.trim(),
          point: point,
          id: _id,
          min: min,
          max: max
        })
      );
    }
  };

  const handleValidationUpdateMarket = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdateMarket.name || fieldsUpdateMarket.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (
      !fieldsUpdateMarket.shortName ||
      fieldsUpdateMarket.shortName.trim() === ""
    ) {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdateMarket.point || fieldsUpdateMarket.point === "") {
      formIsValid = false;
      errors.point = DashboardJSON.ErrorMsg.point;
    }

    setErrorsUpdateMarket(errors);
    return formIsValid;
  };

  const handleFile = async (e) => {
    dispatch(userActions.uploadImage(e));
  };

  let { users } = selector ? selector : {};
  let { fileData, loading } = users ? users : {};

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen overflow-y-auto">
            <div className="flex w-full overflow-hidden ">
              <div className="fixed inset-0 z-40 bg-black bg-opacity-50 flex justify-center items-center overflow-y-auto">

                <main className="relative flex flex-col w-full max-w-lg bg-white p-6 rounded-lg shadow-xl z-50 ">

                  <div className="p-1 flex justify-end items-center mb-4">
                    <IoIosCloseCircle
                      className="text-3xl cursor-pointer"
                      onClick={() => navigate("/app/market")}
                    />
                  </div>
                  <div className=" space-y-5">
                    <div className="mx-auto max-w-screen-3xl ">
                      <div className="w-full p-5  bg-white rounded-xl">
                        <div className="flex justify-between items-center border-b border-gray-400 pb-3">
                          <span className="text-lg text-left font-semibold text-gray-900">
                            Edit Market
                          </span>
                        </div>
                        <div className="flex space-x-4">
                          <div className=" items-center w-full">
                            <label
                              class="block mb-1 text-sm font-medium text-gray-900"
                              for="name"
                            >
                              Name :
                            </label>
                            <input
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                              id="name"
                              name="name"
                              value={
                                fieldsUpdateMarket && fieldsUpdateMarket["name"]
                                  ? fieldsUpdateMarket["name"]
                                  : ""
                              }
                              type="text"
                              onChange={inputChangeCreate}
                            />
                            {errorsUpdateMarket &&
                              errorsUpdateMarket["name"] ? (
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["name"]}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className=" space-x-5 ">
                          <div className=" items-center w-full">
                            <label
                              class="block mb-1 text-sm font-medium text-gray-900"
                              for="name"
                            >
                              Point :
                            </label>
                            <input
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              id="point"
                              name="point"
                              value={
                                fieldsUpdateMarket &&
                                  fieldsUpdateMarket["point"]
                                  ? fieldsUpdateMarket["point"]
                                  : ""
                              }
                              type="number"
                              onChange={inputChangeCreate}
                            />
                            {errorsUpdateMarket &&
                              errorsUpdateMarket["point"] ? (
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["point"]}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex justify-between place-items-center space-x-5 mt-1">
                          <div className=" w-full">
                            <label
                              class="block mb-1 text-sm font-medium text-gray-900"
                              for="name"
                            >
                              Min Amount :
                            </label>
                            <input
                              className="bg-gray-50 mb-1 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              id="min"
                              name="min"
                              value={
                                fieldsUpdateMarket && fieldsUpdateMarket["min"]
                                  ? fieldsUpdateMarket["min"]
                                  : ""
                              }
                              type="number"
                              onChange={inputChangeCreate}
                            />
                            {errorsUpdateMarket && errorsUpdateMarket["min"] ? (
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["min"]}
                              </div>
                            ) : null}
                          </div>
                          <div className="  w-full">
                            <label
                              class="block mb-1 text-sm font-medium text-gray-900"
                              for="name"
                            >
                              Max Amount :
                            </label>
                            <input
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              id="max"
                              name="max"
                              value={
                                fieldsUpdateMarket && fieldsUpdateMarket["max"]
                                  ? fieldsUpdateMarket["max"]
                                  : ""
                              }
                              type="number"
                              onChange={inputChangeCreate}
                            />
                            {errorsUpdateMarket && errorsUpdateMarket["max"] ? (
                              <div className="text-red-600  whitespace-nowrap invalid-feedback">
                                {errorsUpdateMarket["max"]}
                              </div>
                            ) : null}
                          </div>

                        </div>



                        <div className=" flex flex-col space-y-3 border-b border-gray-400 pb-2">
                          <div className=" pb-3 border-gray-400 ">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Upload Image:</label>
                            <input
                              className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                              // style={{ display: 'none' }}
                              id="image"
                              name="image"
                              type="file"
                              onChange={handleFile}
                            />
                          </div>

                          <div className="flex justify-center items-center w-full">
                            <label
                              class="block text-gray-700 text-base whitespace-nowrap w-1/3"
                              for="name"
                            >
                              Image :{" "}
                            </label>
                            {fileData ? (
                              <img
                                src={fileData}
                                alt=" "
                                className=" w-32 h-32 object-cover"
                              />
                            ) : (
                              <img
                                src={
                                  fileData ||
                                    (fieldsUpdateMarket &&
                                      fieldsUpdateMarket.image)
                                    ? fieldsUpdateMarket.image
                                    : "NA"
                                }
                                alt=" "
                                className=" w-32 h-32 object-cover"
                              />
                            )}


                          </div>
                        </div>

                        <div className="flex space-x-5 pt-4 justify-center">

                          <button
                            className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                            onClick={(e) => updateMarketSubmit(e)}
                          >
                            Submit
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMarket;
